import { Auth } from '@aws-amplify/auth';
import { serializeError } from 'serialize-error';
import CloudWatchLogs from 'aws-sdk/clients/cloudwatchlogs';


const isProd = process.env.NODE_ENV !== 'development';

class CloudWatchLogger {
  constructor({ accessKeyId, secretAccessKey, sessionToken, logGroupName, logStreamName }) {
    this.CloudWatchLogs = new CloudWatchLogs({
      apiVersion: '2014-03-28',
      region: process.env.AWS_REGION,
      credentials: {
        accessKeyId,
        secretAccessKey,
        sessionToken,
      }
    });
    this.logGroupName = logGroupName;
    this.logStreamName = logStreamName;
    this.sequenceToken = null;
  }

  // creates a new CloudWatch log stream asynchronously and returns `this`
  async connect() {
    const streamParams = {
      logStreamName: this.logStreamName,
      logGroupName: this.logGroupName
    };

    const responseHandler = (resolve, reject) => (err, data) => {
      if (err) {
        reject(err);
      }
      else {
        resolve(this);
      }
    };

    return new Promise((resolve, reject) => {
      this.CloudWatchLogs.createLogStream(streamParams, responseHandler(resolve, reject));
    });
  }

  // sends JSON-encoded arguments as individual log events to the CW log
  async log(...messages) {
    const logEvents = messages.map(m => {
      return {
        message: JSON.stringify(m),
        timestamp: Date.now()
      }
    });

    const streamParams = {
      logEvents,
      sequenceToken: this.sequenceToken,
      logStreamName: this.logStreamName,
      logGroupName: this.logGroupName
    };

    const responseHandler = (resolve, reject) => (err, data) => {
      if (err) {
        reject(err);
      } else {
        this.sequenceToken = data.nextSequenceToken;
        resolve(data);
      }
    };

    return new Promise((resolve, reject) => {
      this.CloudWatchLogs.putLogEvents(streamParams, responseHandler(resolve, reject));
    });
  }

  getAWSObject() {
    return this.CloudWatchLogs;
  }
};

const sendError = async (appName, message, error) => {
  try {
    const config = {
      logGroupName: `/t2/${appName}`,
      logStreamName: (await Auth.currentAuthenticatedUser()).username,
    };

    const logger = new CloudWatchLogger({
      ...config,
      ...(await Auth.currentCredentials())
    });
    await logger.connect();
    const logResult = await logger.log(message, error);
  } catch { };
};

export const errorHandler = (appName) => (event) => sendError(appName, event.message, event.error);
export const rejectionHandler = (appName) => (event) => sendError(appName, 'Unhandled promise rejection', JSON.stringify(serializeError(event.reason)));
export const error = (appName) => (err) => sendError(appName, 'Exception', JSON.stringify(serializeError(err)));
const elmError = (appName) => (event) => (
  isProd
    ? sendError(appName, event.message, event.error)
    : console.error(event)
);

/*
 * This function binds the ports defined in Log.elm to an external logging
 * service.
 */
export const bind = (app, appName) => {
  if (!app.ports || !(app.ports.toError)) {
    console.error('Could not find logging port');
    return;
  }
  app.ports.toError.subscribe(elmError(appName));
};
export default bind;
