import dotenv from 'dotenv';
import { Auth } from '@aws-amplify/auth';
import { Amplify, Hub, Logger } from '@aws-amplify/core';
import { Elm } from '../elm/Main.elm';
import * as logging from '../js-shared/logging';
import { bindAuth } from '../js-shared/cognito';
import updateCheck from '../js-shared/updatecheck';
import * as camera from './camera';
import { bind as bindNotification } from "./notification.js";

// Styling
import '../css/main.css';
import '../css/daterangepicker.css';
import '@fortawesome/fontawesome-free/css/svg-with-js.min.css';
import '@polymer/app-layout/app-layout';
import '@polymer/paper-item/paper-item';
import '@polymer/iron-icons/iron-icons';
import '@polymer/iron-icons/maps-icons';
import '@polymer/iron-icons/hardware-icons';
import '@polymer/iron-icons/communication-icons';
import '@polymer/paper-icon-button/paper-icon-button';
import '@fooloomanzoo/datetime-picker/datetime-picker';

dotenv.config();
const isProd = process.env.NODE_ENV !== 'development';
if (isProd) {
  window.addEventListener('error', logging.errorHandler('staff'));
  window.addEventListener('unhandledrejection', logging.rejectionHandler('staff'));
  console.error = logging.error('staff');
  updateCheck();
}
if ('serviceWorker' in navigator) {
  window.addEventListener('load', navigator.serviceWorker.register('../service-worker.js'));
}
const logger = new Logger('app');

const popStorage = (key) => {
  let item = sessionStorage.getItem(key);
  sessionStorage.removeItem(key);
  return item;
};

Amplify.configure({
  Auth: {
    region: process.env.AWS_REGION,
    identityPoolId: process.env.COGNITO_ID_POOL_ID,
    userPoolId: process.env.COGNITO_POOL_ID,
    userPoolWebClientId: process.env.COGNITO_CLIENT_ID,
    mandatorySignIn: true,
    oauth: {
      domain: process.env.COGNITO_DOMAIN,
      redirectSignIn: isProd ? process.env.COGNITO_SIGNIN_URL : 'http://localhost:1234',
      redirectSignOut: isProd ? process.env.COGNITO_SIGNOUT_URL : 'http://localhost:1234/sign-out',
      responseType: 'code',
      scope: ['phone', 'email', 'openid', 'aws.cognito.signin.user.admin', 'profile'],
    },
  },
  API: {
    endpoints: [
      {
        name: 'T2',
        endpoint: process.env.API_URL,
        custom_header: async () => ({
          Authorization: `Bearer ${(await Auth.currentSession()).idToken.jwtToken}`,
        }),
      },
    ],
  },
});

const isAuthInProgress = () => sessionStorage.getItem('auth_in_progress') !== null;
const setAuthInProgress = () => {
  sessionStorage.setItem('auth_in_progress', true);
  sessionStorage.setItem('initial_url', window.location.href);
}
const clearAuthInProgress = () => sessionStorage.removeItem('auth_in_progress');

const start = (user) => {
  if (isProd) {
    // Analytics, etc.
  }
  let initial_url = popStorage('initial_url');
  if (initial_url) {
    window.location.replace(initial_url);
  }
  const app = Elm.Main.init({ flags: user.signInUserSession });
  bindAuth(app, async () => { });
  logging.bind(app, 'staff');
  camera.bind(app);
  bindNotification(app);
};



Hub.listen('auth', ({ payload: { event } }) => {
  switch (event) {
    case 'signIn':
      clearAuthInProgress();
      Auth.currentAuthenticatedUser().then(start).catch(logger.error);
      break;
    default:
  }
});

if (!isAuthInProgress()) {
  Auth.currentAuthenticatedUser()
    .then(start)
    .catch(() => {
      setAuthInProgress();
      Auth.federatedSignIn();
    });
}
