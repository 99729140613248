const interval = 10 * 60 * 1000; // 10 minutes

const addMessage = () => {
  const updateDiv = document.createElement('div');
  updateDiv.appendChild(document.createTextNode('Update available, click here to reload'));
  updateDiv.style.cssText = 'background-color: #ef2929; color: white; position: fixed; top: 0px; left: 0px; width: 100%; padding: 8px; text-align: center; cursor: pointer; font-family: Source Sans Pro, sans-serif;';
  updateDiv.onclick = () => window.location.reload(true);
  document.body.appendChild(updateDiv);
};

export default function updateCheck() {
  const intervalId = setInterval(() => {
    fetch('/', {
      method: 'HEAD',
      cache: 'no-cache',
      headers: {
        'If-Modified-Since': new Date(
          Date.parse(document.lastModified),
        ).toUTCString(),
      },
    })
      .then((response) => {
        if (response.status === 200) {
          addMessage();
          clearInterval(intervalId);
        }
      });
  }, interval);
}
