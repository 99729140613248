import beep from '../assets/audio/beep.wav';

const audioBeep = new Audio(beep);

const getMediaStream = () => navigator.mediaDevices.getUserMedia({
  audio: false,
  video: {
    facingMode: {
      exact: 'environment',
    },
  },
}).catch((err) => {
  throw new Error('No camera support');
});

const getBarcodeDetector = () => Promise.resolve().then(() => {
  if (!('BarcodeDetector' in window)) {
    throw new Error('No barcode support');
  }
  return new BarcodeDetector({ formats: ['qr_code'] });
})

const streamMedia = (mediaStream) => {
  requestAnimationFrame(() => {
    const video = document.querySelector('video');
    if (video) {
      video.srcObject = mediaStream;
    }
  });
  return mediaStream;
};

const stopMediaStream = (mediaStream) => {
  try {
    if (mediaStream) {
      mediaStream.getTracks().forEach((track) => track.stop());
    }
  } catch (e) {
    console.error(e);
  }
  return null;
};

const detectBarcodes = async (barcodeDetector) => {
  const video = document.querySelector('video');
  if (!video || !video.videoHeight || !video.videoWidth) {
    return [];
  }
  const canvas = document.createElement('canvas');
  canvas.width = video.videoWidth;
  canvas.height = video.videoHeight;
  const context = canvas.getContext('2d');
  context.imageSmoothingEnabled = false; // gives less blurry images
  context.drawImage(video, 0, 0, video.videoWidth, video.videoHeight);
  const codes = await barcodeDetector.detect(
    context.getImageData(0, 0, canvas.width, canvas.height),
  );
  if (codes.length) {
    audioBeep.play();
  }
  return codes.map((code) => code.rawValue);
};

const sendQrCode = (toElm, qrCodeData) => toElm.send({ msgType: 'qrcode', msg: qrCodeData });
const sendError = (toElm, e) => toElm.send({ msgType: 'error', msg: e.message });

const startQrScan = (toElm, barcodeDetector) => setInterval(() => {
  detectBarcodes(barcodeDetector)
    .then((codes) => codes.length && sendQrCode(toElm, codes[0]))
    .catch((e) => sendError(toElm, e));
}, 1000);

const stopQrScan = (intervalId) => clearInterval(intervalId);

export const bind = (app) => {
  if (!app.ports || !(app.ports.toCamera && app.ports.fromCamera)) {
    console.error('Could not find camera ports');
    return;
  }
  const toElm = app.ports.fromCamera;
  let intervalId;
  let mediaStream;

  app.ports.toCamera.subscribe((message) => {
    switch (message.msgType) {
      case 'startQrScan':
        Promise.all([getBarcodeDetector(), getMediaStream()])
          .then(([barcodeDetector, stream]) => {
            mediaStream = streamMedia(stream);
            intervalId = startQrScan(toElm, barcodeDetector);
          })
          .catch((err) => sendError(toElm, err));
        break;
      case 'stopQrScan':
        stopQrScan(intervalId);
        mediaStream = stopMediaStream(mediaStream);
        break;
      default:
        console.error('Unknown message type: ', message.msgType);
        break;
    }
  });
};

export default bind;
