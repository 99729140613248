import { Auth } from '@aws-amplify/auth';
//import CognitoIdentityServiceProvider from 'aws-sdk/clients/cognitoidentityserviceprovider';


/*
 * This function binds the ports defined in Cognito.elm to
 * the Cognito Amplify API.
 */
export function bindAuth(app, preSignOut) {
  // Exit predictably if the ports aren't in use in Elm
  if (!app.ports || !(app.ports.toCognito && app.ports.cognitoEvent)) {
    console.error('Could not find Cognito ports on app');
    return;
  }

  // Utility Functions
  const sendSession = app.ports.cognitoEvent.send;
  const sendDeliveryDetails = app.ports.cognitoEvent.send;
  const sendMfaDetails = (user) => app.ports.cognitoEvent.send(user.challengeParam);
  const sendRequiredAttributes = (user) => app.ports.cognitoEvent.send(
    user.challengeParam.requiredAttributes,
  );
  const sendTotpCode = app.ports.cognitoEvent.send;
  const sendError = app.ports.cognitoEvent.send;

  const retryOptions = {
    times: 20,
    interval: (retryCount) => 250 * (2 ** retryCount),
  };

  const refreshToken = async (existingJti, attempt = 1) => {
    if (navigator.onLine) {
      try {
        const session = await Auth.currentSession();
        const { jti } = session.accessToken.payload;
        if (existingJti !== jti) {
          sendSession(session);
        }
        setTimeout(refreshToken, 1000, jti);
      } catch (err) {
        switch (err.code) {
          case 'NetworkError':
            // Exponential backoff
            if (attempt < retryOptions.times) {
              setTimeout(refreshToken, retryOptions.interval(attempt), existingJti, attempt + 1);
            }
            break;
          case 'NotAuthorizedException':
            window.location.reload();
            break;
          default:
            if (err !== 'No current user') {
              throw err;
            }
        }
      }
    } else {
      window.addEventListener('online', refreshToken);
    }
  };

  const start = (user) => {
    sendSession(user.signInUserSession);
    setTimeout(refreshToken, 1000);
  };

  const stop = async (opts) => {
    await preSignOut();
    await Auth.signOut(opts);
  };

  const handleChallenge = async (user) => {
    switch (user.challengeName) {
      case 'SMS_MFA':
      case 'SOFTWARE_TOKEN_MFA':
        sendMfaDetails(user);
        app.ports.toCognito.subscribe(function confirmSignIn(message) {
          if (message.msgType === 'confirmSignIn') {
            const {
              code,
            } = message.msg;
            Auth.confirmSignIn(user, code, user.challengeName)
              .then(start)
              .then(() => app.ports.toCognito.unsubscribe(confirmSignIn))
              .catch(sendError);
          }
        });
        break;
      case 'NEW_PASSWORD_REQUIRED':
        // User account was created in Cognito console. The user needs to input
        // a new password and required attributes.
        sendRequiredAttributes(user);
        app.ports.toCognito.subscribe(function completeNewPassword(message) {
          if (message.msgType === 'completeNewPassword') {
            const {
              newPassword,
              requiredAttributes,
            } = message.msg;
            Auth.completeNewPassword(user, newPassword, requiredAttributes)
              .then(handleChallenge)
              .then(() => app.ports.toCognito.unsubscribe(completeNewPassword))
              .catch(sendError);
          }
        });
        break;
      case 'MFA_SETUP':
        // The MFA method is TOTP which requires the user to go through some
        // steps to generate those passwords.
        Auth.setupTOTP(user)
          .then(sendTotpCode)
          .catch(sendError);
        break;
      default:
        // No challenges, we're signed-in with a valid session
        start(user);
    }
  };

  const signIn = async ({ username, password }) => {
    try {
      const user = await Auth.signIn(username, password);
      await handleChallenge(user);
    } catch (error) {
      switch (error.code) {
        case 'UserNotConfirmedException':
          // User didn't finish confirmation in sign-up, resend code.
          Auth.resendSignUp(username)
            .then(sendDeliveryDetails)
            .catch(sendError);
          break;
        case 'PasswordResetRequiredException':
          // Password has been reset in Cognito console, initiate password
          // reset which sends a code by SMS or email to the user. Show the
          // user the delivery details so they know where to check for the
          // code.
          Auth.forgotPassword(username)
            .then(sendDeliveryDetails)
            .catch(sendError);
          break;
        default:
          // Handles:
          // * NotAuthorizedException: incorrect password is provided.
          // * UserNotFoundException: the supplied username/email does not
          //   exist in the Cognito user pool.
          sendError(error);
      }
    }
  };

  // Handle events from Elm
  app.ports.toCognito.subscribe((message) => {
    switch (message.msgType) {
      case 'signIn':
        signIn(message.msg);
        break;
      case 'signOut':
        stop(message.msg);
        break;
      case 'changePassword': {
        const {
          oldPassword,
          newPassword,
        } = message.msg;
        Auth.currentAuthenticatedUser()
          .then((user) => {
            Auth.changePassword(user, oldPassword, newPassword);
            return user;
          })
          .then(sendSession)
          .catch(sendError);
        break;
      }
      case 'forgotPassword': {
        const {
          username,
        } = message.msg;
        Auth.forgotPassword(username)
          .then(sendDeliveryDetails)
          .catch(sendError);
        break;
      }
      case 'forgotPasswordSubmit': {
        const {
          username,
          code,
          newPassword,
        } = message.msg;
        Auth.forgotPasswordSubmit(username, code, newPassword)
          .then(() => signIn({ username, password: newPassword }))
          .catch(sendError);
        break;
      }
      case 'updateUserAttributes': {
        const attributes = message.msg;
        Auth.currentAuthenticatedUser()
          .then((user) => {
            Auth.updateUserAttributes(user, attributes);
            return user;
          })
          .then(sendSession)
          .catch(sendError);
        break;
      }
      default:
        break;
    }
  });

  refreshToken();
}


/*
export const getUsers = () => Auth.currentCredentials()
  .then(credentials =>
    new CognitoIdentityServiceProvider({
      credentials: Auth.essentialCredentials(credentials),
      region: 'eu-west-1',
    }))
  .then(cisp => {
    const params = {
      UserPoolId: 'eu-west-1_OHbRbAGZB',
    };
    return new Promise((resolve, reject) =>
      cisp.listUsers(params, (err, data) =>
        err ? reject(err) : resolve(data)));
  });
  */
